import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { URL_API } from "../config/config";
import { Producto } from '../models/productos.model';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

constructor(private http: HttpClient) { }

  get_listar(pagina: string, limit: string) {
    const url = URL_API + '/productos/get_listar/' + pagina + '/' + limit;
    return this.http.get(url);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    const url = URL_API + '/productos/get_buscar/' + pagina + '/' + limit + '/' + buscar;
    return this.http.get(url);
  }

  create(model: any) {
    const url = URL_API + '/productos/nuevo';
    return this.http.post(url, model, this.httpOptions);
  }

  getbyid(id: string) {
    const url = URL_API + '/productos/get_byid/' + id;
    return this.http.get(url);
  }

  update(id: string, model: Producto) {
    const url = URL_API + '/productos/update/' + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete (id: string)  {
    const url = URL_API + '/productos/delete/' + id;
    return this.http.delete(url, this.httpOptions);
  }
}
