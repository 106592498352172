import { Component, OnInit } from '@angular/core';
import { GraphVentasService } from 'src/app/services/graph-ventas.service';
import * as global from '../../config/globals';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit {
  global = global;
  data_galones = [];
  data_productos = [];
  data_venta = [];
  data_venta_producto = [];
  data_venta_mes = [];
  data_venta_bymes = [];

  data_g = [];
  data_ventag = [];
  data_chart = [];
  data_chart_ventaproducto = [];

  mes_nombre = '';
  mes_venta = 0;
  mes_galones = 0;
  mes_descuento = 0;
  year = 0;
  saldo_acumulado = 0;

  constructor(
    private _serviceGrapVentas: GraphVentasService
  ) { }

  lineChartColor = { domain: [global.COLOR_BLUE, global.COLOR_GREEN, global.COLOR_PURPLE, global.COLOR_BLACK] };

  ChartGalonesDia = [];
  ChartVentasDia = [];
  ChartVentasByMes = [];
  pieChartDataProductos = [];
  pieChartVentaProductos = [];

  pieChartColor = { domain: [global.COLOR_RED, global.COLOR_ORANGE, global.COLOR_BLACK] };

  ngOnInit() {
    this._serviceGrapVentas.get_venta_bymes().subscribe((resp: any) => {
      this.data_venta_bymes = resp.data;
      console.log('this.data_venta_bymes => ', this.data_venta_bymes);
      this.data_venta_bymes.forEach((x: any) => {
        this.data_g.push({name: x.mes_nombre, value: x.venta_neta})
      })
       this.ChartVentasByMes = [{
        "name": "venta Neta",
        "series": this.data_g
      }];
    });

    this._serviceGrapVentas.get_ventabyproducto().subscribe((resp: any) => {
      this.data_venta_producto = resp.data;
      this.data_venta_producto.forEach((x: any) => {
        this.data_chart_ventaproducto.push({name: x.producto_nombre, value: x.venta_neta})
      })
      this.pieChartVentaProductos = this.data_chart_ventaproducto;
    });

    this._serviceGrapVentas.get_ventabyproductoultimosdias().subscribe((resp: any) => {
      this.data_productos = resp.data;
      this.data_productos.forEach((x: any) => {
        this.data_chart.push({name: x.producto_nombre, value: x.venta_neta})
      })
      this.pieChartDataProductos = this.data_chart;
    });

    /* this._serviceGrapVentas.get_galonesbyproducto().subscribe((resp: any) => {
      this.data_productos = resp.data;
      this.data_productos.forEach((x: any) => {
        this.data_chart.push({name: x.producto_nombre, value: x.total_galones})
      })
       this.pieChartDataProductos = this.data_chart;
    });
 */

    this._serviceGrapVentas.get_venta_bydia().subscribe((resp: any) => {
      this.data_venta = resp.data;
      this.data_venta.forEach((x: any) => {
        this.data_ventag.push({name: x.fecha_venta, value: x.venta_neta})
      })

       this.ChartVentasDia = [{
        "name": "Venta Neta",
        "series": this.data_ventag
      }];
    });


    


    this._serviceGrapVentas.get_venta_mes_actual().subscribe((resp: any) => {
      this.data_venta_mes = resp.data;
       this.mes_nombre = this.data_venta_mes[0].mes_nombre;
       this.mes_venta = this.data_venta_mes[0].venta_neta;
       this.mes_galones = this.data_venta_mes[0].total_galones;
       this.mes_descuento = this.data_venta_mes[0].total_descuento;
       this.year = this.data_venta_mes[0].year;
    });


    this._serviceGrapVentas.get_graph_saldo_descuento().subscribe((resp: any) => {
      console.log("saldo acumulado: ", resp.data[0].saldo);
      this.saldo_acumulado = resp.data[0].saldo;
    });



    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }


  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }
}
