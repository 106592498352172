import { Component, OnInit } from '@angular/core';
import pageSettings from './config/page-settings';
import { AuthorizatedGuard } from './auth/authorizated-guard';
import { User } from './models/auth/users.model';
import { UsersService } from './services/users.service';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AuthenticationService, AuthorizatedGuard]
})
export class AppComponent implements OnInit {
  pageSettings;
  pageHasScroll;
  currentUser: User;

  title = 'Estación de Servicio';

  constructor(private _serviceUsers: UsersService, private router: Router) {
    this._serviceUsers.currentUser.subscribe(x => {
      this.currentUser = x
        if (x) {
          this._serviceUsers.isLogueado$.emit(true);
        }
    });
    
  }

  ngOnInit() {
    this.pageSettings = pageSettings;
  }

 

  onToggleSidebarRight(val: boolean): void {

  }

  onToggleMobileSidebar(val: boolean): void {

  }

  onToggleMobileRightSidebar(val: boolean): void {

  }

  onToggleSidebarMinified(val: boolean): void {

  }

  onHideMobileSidebar(val: boolean): void {

  }

  onHideMobileRightSidebar(val: boolean): void {

  }

  logOut(msg: string) {
    this._serviceUsers.logout();
    this.router.navigate(["/login"]);
  }
}
