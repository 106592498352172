import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { ClientesComponent } from './pages/clientes/clientes.component';
import { EmpresaComponent } from './pages/empresa/empresa.component';
import { VehiculosComponent } from './pages/vehiculos/vehiculos.component';
import { MarcasComponent } from './pages/marcas/marcas.component';
import { TarifasDescuentosComponent } from './pages/tarifas-descuentos/tarifas-descuentos.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { VentasComponent } from './pages/ventas/ventas.component';
import { ReportesComponent } from './pages/reportes/reportes.component';
import { UsersComponent } from './auth/users.component';
import { RegisterComponent } from './auth/register.component';
import { LoginComponent } from './auth/login.component';
import { AuthorizatedGuard } from './auth/authorizated-guard';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RetiroDescuentosComponent } from './pages/retiro-descuentos/retiro-descuentos.component';
import { ConsultaDescuentosComponent } from './pages/retiro-descuentos/consulta-descuentos.component';

const routes: Routes = [
  { path: 'clientes', component: ClientesComponent, canActivate: [AuthorizatedGuard], data: {title: 'Clientes'}  },
  { path: 'empresa', component: EmpresaComponent, canActivate: [AuthorizatedGuard], data: { title: 'Configuración Empresa'} },
  { path: 'marcas', component: MarcasComponent, canActivate: [AuthorizatedGuard], data: { title: 'Configuración Marcas'} },
  { path: 'vehiculos', component: VehiculosComponent, canActivate: [AuthorizatedGuard], data: { title: 'Configuración Vehículos'} },
  { path: 'tarifas', component: TarifasDescuentosComponent, canActivate: [AuthorizatedGuard], data: { title: 'Configuración Tarifas Descuentos'} },
  { path: 'ventas', component: VentasComponent, canActivate: [AuthorizatedGuard], data: { title: 'Registro de Ventas'} },
  { path: 'productos', component: ProductosComponent, canActivate: [AuthorizatedGuard], data: { title: 'Registro de Productos'} },
  { path: 'reportes', component: ReportesComponent, canActivate: [AuthorizatedGuard], data: { title: 'Reportes' }},
  { path: 'usuarios', component: UsersComponent, canActivate: [AuthorizatedGuard], data: {title: 'Usuarios del Sistema'}},
  { path: 'register', component: RegisterComponent, data: { title: 'Registro de Usuarios'}},
  { path: 'login', component: LoginComponent, data: { title: 'Inicio de Sesión'} },
  { path: 'home', component: HomeComponent, canActivate: [AuthorizatedGuard]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthorizatedGuard]},
  { path: 'retiro-descuentos', component: RetiroDescuentosComponent, canActivate: [AuthorizatedGuard]},
  { path: 'consulta-descuentos', component: ConsultaDescuentosComponent, canActivate: [AuthorizatedGuard]},
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: '**', component: PageNotFoundComponent}

];

@NgModule ({
  imports: [
      CommonModule,
      BrowserModule,
      RouterModule.forRoot(routes, {
          useHash: true
      })
  ],
  exports: []
})

export class AppRoutingModule {}
