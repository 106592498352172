import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { PaginadorService } from '../paginador/paginador.service';

@Component({
  selector: 'app-paginador',
  templateUrl: './paginador.component.html',
  styles: []
})
export class PaginadorComponent implements OnInit {

  registros: number = 0;
  page: number = 0;
  recxpag: number = 10;
  componente: string = '';
  pageSizeOptions: number[] = [10, 25, 100];

  message: string = "Hello!";

  @Output() messageEvent = new EventEmitter<any>();

  constructor(private _servicePaginador: PaginadorService) { 
  
  }


  ngOnInit(): void {
    this._servicePaginador.page$.subscribe((resp: any) => {
      this.page = resp;
    });

    this._servicePaginador.registros$.subscribe((resp: any) => {
      this.registros = resp;
    });

  }

  loadPage(e: any) {
    this.messageEvent.emit({pagina: e, recxpag: this.recxpag});
  }

  changeRegxPagina(pagina: number) {
    this.messageEvent.emit({pagina: 1, recxpag: this.recxpag});
  }
  sendMessage() {
  }

}
