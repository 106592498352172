import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmpresaService } from '../services/empresa.service';
import { User } from '../models/auth/users.model';

@Component({
  selector: 'app-users-new',
  templateUrl: './users-new.component.html',
  styles: [
    `
    input.form_control{ margin-top: 4px;}
    `
  ],
})
export class UsersNewComponent implements OnInit {
  isLoading: boolean = false;
  frm: FormGroup;
  _model: User;

  isEdit: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceEmpresa: EmpresaService,
    ) { }

  ngOnInit() {
        this.frm = this.fb.group({
        user_id: [0],
        nombre: ['', Validators.required],
        email: ['', Validators.email],
        activo: [1],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      });

    if (this.isEdit) {
      const id = this._model.user_id;
      this.setForm(id);
    }
    
  }
  get fdata() { return this.frm.controls; }

  private setForm (id: number) {
    this._serviceEmpresa.getbyid(id.toString()).subscribe((resp: any) => {
        this._model = resp.data[0];
        this.frm = this.fb.group({
          user_id: [this._model.user_id],
          nombre: [this._model.nombre, Validators.required],
          email: [this._model.email, Validators.email],
          activo: [this._model.activo],
        });
    });

  }
}
