import { UsersService } from './../../services/users.service';
import { Component, Input, Output, EventEmitter, Renderer2, OnDestroy, OnInit } from '@angular/core';
import pageSettings from '../../config/page-settings';
import { User } from 'src/app/models/auth/users.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnDestroy, OnInit {

  @Input() pageSidebarTwo;
	@Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
	@Output() toggleMobileSidebar = new EventEmitter<boolean>();
	@Output() toggleMobileRightSidebar = new EventEmitter<boolean>();
	pageSettings = pageSettings;

	@Input() currentUser: User;
    @Output() logOutMsgEmit = new EventEmitter<string>();
	empresa = '';
	empresa2 = '';
	isLogueado: boolean = false;

  mobileSidebarToggle() {
		this.toggleMobileSidebar.emit(true);
  }
  mobileRightSidebarToggle() {
		this.toggleMobileRightSidebar.emit(true);
  }
	toggleSidebarRight() {
		this.toggleSidebarRightCollapsed.emit(true);
	}

	mobileTopMenuToggle() {
	  this.pageSettings.pageMobileTopMenuToggled = !this.pageSettings.pageMobileTopMenuToggled;
	}

	mobileMegaMenuToggle() {
	  this.pageSettings.pageMobileMegaMenuToggled = !this.pageSettings.pageMobileMegaMenuToggled;
	}

	ngOnInit() {
		this._serviceUsers.empresa$.subscribe(resp => {
			this.empresa = resp;
		});

		this._serviceUsers.empresa2$.subscribe(resp => {
			this.empresa2 = resp;
		});

		this._serviceUsers.isLogueado$.subscribe(resp => {
			this.isLogueado = resp;
		});
	}

	ngOnDestroy() {
	  this.pageSettings.pageMobileTopMenuToggled = false;
	  this.pageSettings.pageMobileMegaMenuToggled = false;
	}

  constructor(
	  private renderer: Renderer2,
	  public _serviceUsers: UsersService
	  ) {

  }
  
  public logout(): void{
	// sessionStorage.removeItem('currentUser');
	this._serviceUsers.isLogueado$.emit(false);
	this.logOutMsgEmit.emit('logout');
  }

}
