import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { URL_API } from "../config/config";
import { Cliente } from '../models/clientes.model';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

constructor(private http: HttpClient) { }

  get_listar(page: string, limit: string) {
    const url = URL_API + '/clientes/get_listar/' + page + '/' + limit;
    return this.http.get(url);
  }

  get_listar_vclientes(page: string, limit: string) {
    const url = URL_API + '/clientes/get_listar_vclientes/' + page + '/' + limit;
    return this.http.get(url);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    const url = URL_API + '/clientes/get_buscar/' + pagina + '/' + limit + '/' + buscar;
    return this.http.get(url);
  }

  create(model: any) {
    const url = URL_API + '/clientes/nuevo';
    return this.http.post(url, model, this.httpOptions);
  }

  getbyid(id: string) {
    const url = URL_API + '/clientes/get_byid/' + id;
    return this.http.get(url);
  }

  update(id: string, model: Cliente) {
    const url = URL_API + '/clientes/update/' + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete (id: string)  {
    const url = URL_API + '/clientes/delete/' + id;
    return this.http.delete(url, this.httpOptions);
  }


}
