import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from "../config/config";
import { TarifaDescuento } from '../models/tarifas-descuentos.model';

@Injectable({
  providedIn: 'root'
})
export class TarifasDescuentosService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

  constructor(
    private http: HttpClient
  ) { }

  get_listar() {
    const url = URL_API + '/tarifas_descuentos/get_listar';
    return this.http.get(url);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    const url = URL_API + '/tarifas_descuentos/get_buscar/' + pagina + '/' + limit + '/' + buscar;
    return this.http.get(url);
  }

  create(model: any) {
    const url = URL_API + '/tarifas_descuentos/nuevo';
    return this.http.post(url, model, this.httpOptions);
  }

  getbyid(id: string) {
    const url = URL_API + '/tarifas_descuentos/get_byid/' + id;
    return this.http.get(url);
  }

  update(id: string, model: TarifaDescuento) {
    const url = URL_API + '/tarifas_descuentos/update/' + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete (id: string)  {
    const url = URL_API + '/tarifas_descuentos/delete/' + id;
    return this.http.delete(url, this.httpOptions);
  }
}
