import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Venta } from '../../models/ventas.model';
import { VehiculosService } from '../../services/vehiculos.service';
import { MatDialog } from '@angular/material';
import { ProductosService } from '../../services/productos.service';
import { DlgVehiculosComponent } from '../../busquedas/dlg-vehiculos.component';
import { VentasService } from '../../services/ventas.service';
import { VentasValidatorComponent } from './ventas-validator.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-ventas-new',
  templateUrl: './ventas-new.component.html',
  styles: [
    `
    input.form_control{ margin-top: 4px;}
    `
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class VentasNewComponent implements OnInit {
  isLoading: boolean = false;
  frm: FormGroup;
  productos = [];
  vehiculos = [];
  data_search_clientes = [];
  _model: Venta;
  _cliente = "";
  isEdit: boolean = false;
  _vehiid: number = 0;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceProductos: ProductosService,
    private _serviceVentas: VentasService,
    private _serviceVehiculos: VehiculosService,
    private dialog: MatDialog,
    // private VentasValidator: VentasValidatorComponent
    ) { }

  ngOnInit() {
        this.frm = this.fb.group({
        venta_id: [0],
        vehiculo_id: ['', Validators.required],
        producto_id: ['', Validators.required],
        numero_recibo: ['', {
          validators: [Validators.required],
          asyncValidators : [VentasValidatorComponent.existingReciboValidator(this._serviceVentas, true)],
          updateOn: 'blur'
          }],
        fecha_venta: ['', Validators.required],
        valor_pesos: [0, Validators.required],
        precio_por_galon: ['', Validators.required],
        descuento_por_galon: ['', Validators.required],
        total_galones: ['', Validators.required],
        _total_venta: [''],
        _total_descuento: [''],
        _total_venta_neta: [''],
        _cliente_nombre: ['']
      });

    this._serviceVehiculos.get_listar('1', '10').subscribe((resp: any) => {
      this.vehiculos = resp.data;
    });

    this._serviceProductos.get_listar('1', '10').subscribe((resp: any) => {
      this.productos = resp.data;
    });

    if (this.isEdit) {
      const id = this._model.venta_id;
      this.setForm(id);
    }
    
  }
  get fdata() { return this.frm.controls; }

  private setForm (id: number) {
    this._serviceVentas.getbyid(id.toString()).subscribe((resp: any) => {
        this._model = resp.data[0];
        
        this.frm = this.fb.group({
          venta_id: [this._model.venta_id],
          vehiculo_id: [this._model.vehiculo_id, Validators.required],
          producto_id: [this._model.producto_id, Validators.required],
          numero_recibo: [this._model.numero_recibo, {
            validators: [Validators.required],
            asyncValidators : [VentasValidatorComponent.existingReciboValidator(this._serviceVentas, false)],
            updateOn: 'blur'
            }],
          fecha_venta: [this._model.fecha_venta, Validators.required],
          valor_pesos: [this._model.valor_pesos, Validators.required],
          precio_por_galon: [this._model.precio_por_galon, Validators.required],
          descuento_por_galon: [this._model.descuento_por_galon, Validators.required],
          total_galones: [this._model.total_galones, Validators.required],
          _total_venta: [this._model.precio_por_galon * this._model.total_galones],
          _total_descuento: [this._model.total_galones * this._model.descuento_por_galon],
          _total_venta_neta: [(this._model.precio_por_galon * this._model.total_galones) - (this._model.descuento_por_galon * this._model.total_galones)],
          _cliente_nombre: [this._cliente],
        });
    });

  }

  openDialog_Vehiculos(): void {
    const dialogRef = this.dialog.open(DlgVehiculosComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_clientes = result;
        this.frm.controls.vehiculo_id.patchValue(result[0].vehiculo_id);
        this.frm.controls.descuento_por_galon.patchValue(result[0].tarifa_descuento);
        this.frm.controls._cliente_nombre.patchValue(result[0].cliente_nombre + ', Vehículo No. ' + result[0].numero);
        this._vehiid = result[0].vehiculo_id;
        dialogSubmitSubscription.unsubscribe();
        this.calculos();
      });
  }

  change_producto (item: any)
  {
    this.frm.controls.precio_por_galon.patchValue(item.precio_venta);
    this.calculos();
  }
  
  calculos(){
    if (this.frm.controls.valor_pesos.value > 0 && (this.frm.controls.precio_por_galon.value !== '' || this.frm.controls.precio_por_galon.value > 0)) {
      let total_galones = this.frm.controls.valor_pesos.value / this.frm.controls.precio_por_galon.value;
      this.frm.controls.total_galones.patchValue(total_galones.toFixed(2));

      const tdescuento = total_galones * this.frm.controls.descuento_por_galon.value;
      this.frm.controls._total_descuento.patchValue(tdescuento.toFixed(2));
 
      const tventa = total_galones *  this.frm.controls.precio_por_galon.value;
      this.frm.controls._total_venta.patchValue(tventa.toFixed(2));

      const tventaneta = tventa - tdescuento;
      this.frm.controls._total_venta_neta.patchValue(tventaneta.toFixed(2));

      
    }
  }

  change(value: string)
  {
    const lastchar = value.substr(value.length - 1);
    if (!(new RegExp('[0-9]').test(lastchar)))
    {
      value = value.substr(0, value.length - 1);
      this.frm.controls["valor_pesos"].setValue(value);

    }
  }

  change_precio_galon(value: string)
  {
    const lastchar = value.substr(value.length - 1);
    if (!(new RegExp('[0-9]').test(lastchar)))
    {
      value = value.substr(0, value.length - 1);
      this.frm.controls["precio_por_galon"].setValue(value);

    }
  }
}
