import { Injectable, EventEmitter } from '@angular/core';
import { URL_API } from "../config/config";
import { User } from "../models/auth/users.model";
import { LoginModel } from '../models/auth/login.model';
import { BehaviorSubject, Observable, of } from "rxjs";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, map } from "rxjs/operators";
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private currentUserSubject: BehaviorSubject<User>;
  currentUser: Observable<User>;
  isAuthenticated: boolean = false;
  empresa$ = new EventEmitter<any>();
  empresa2$ = new EventEmitter<any>();
  isLogueado$ = new EventEmitter<boolean>();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; chrset=utf-8' })
  };

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
   }

   public get currentUserObject(): User {
     return this.currentUserSubject.value;
   }

   get_listar(page: string, limit: string) {
    const url = URL_API + '/users/get_listar/' + page + '/' + limit;
    return this.http.get(url);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    const url = URL_API + '/users/get_buscar/' + pagina + '/' + limit + '/' + buscar;
    return this.http.get(url);
  }

  create(model: any) {
    const url = URL_API + '/users/nuevo';
    return this.http.post(url, model, this.httpOptions);
  }

  update(id: string, model: User) {
    const url = URL_API + '/users/update/' + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete (id: string)  {
    const url = URL_API + '/users/delete/' + id;
    return this.http.delete(url, this.httpOptions);
  }

  emailExiste(email: string) {
    let url = URL_API + `/users/get_emailexiste/${email}`;
    return this.http.get(url)
      .pipe(
        map((resp: any) => resp),
        catchError(err => {
          Swal.fire('Error', err, 'error');
          return of(false);
        })
      );
  }

  
  login(loginModel: LoginModel) {
    sessionStorage.removeItem('currentUser');
    let url = URL_API + '/users/login';
    return  this.http.post<any>(url, loginModel, this.httpOptions)
    .pipe(map(user => {
      sessionStorage.setItem('currentUser', JSON.stringify(user.usuario));
      this.currentUserSubject.next(user.usuario);
      this.isAuthenticated = true;
      return user;
    }));
  }

  getUsers(): Observable<User[]> {
    let url = URL_API + '/users/listar';
    return this.http.get<User[]>(url, this.httpOptions);
  }

  getUserById(id: number): Observable<User> {
    let url = URL_API + '/users/getbyid/' + id;
    return this.http.get<User>(url, this.httpOptions);
  }

  logout() {
    sessionStorage.removeItem('currentUser');
    this.isAuthenticated = false;
    this.currentUserSubject.next(null);
  }
}
