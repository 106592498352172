import { Component, OnInit } from "@angular/core";
import { ReportesService } from "../../services/reportes.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import * as moment from "moment";
import { TiposReporteService } from "src/app/services/tipos-reporte.service";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import Swal from "sweetalert2";
import { RetiroDescuentosService } from "src/app/services/retiro-descuentos.service";

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: "app-reportes",
  templateUrl: "./reportes.component.html",
  styles: [],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class ReportesComponent implements OnInit {
  data_rpt = [];
  form: FormGroup;
  desde: string = "";
  hasta: string = "";
  tiporpt_id: number = 0;
  tipos_rpt = [];
  cargando: boolean = false;
  btndisabled: boolean = false;

  constructor(
    private _serviceReportes: ReportesService,
    private _servcieTiposReporte: TiposReporteService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      fecha_desde: [new Date(), Validators.required],
      fecha_hasta: [new Date(), Validators.required],
      tiporeporte_id: ['', Validators.required],
      vehiculo_numero: [''],
      chkvehiculo: [false]
    });
  }

  ngOnInit() {
    this._servcieTiposReporte.getLista().subscribe((resp: any) => {
      this.tipos_rpt = resp.data;
    });
  }

  get fdata() { return this.form.controls; }

  consultar() {
    console.log("xxxxxxxxxxxxxxxxxxxxx", this.tiporpt_id);
    this.desde = moment(this.form.controls.fecha_desde.value).format(
      "YYYY-MM-DD 00:00"
    );
    this.hasta = moment(this.form.controls.fecha_hasta.value).format(
      "YYYY-MM-DD 23:59"
    );
    this.data_rpt = [];
    let _condicion = "";
    this._serviceReportes.EventDatosVentas$.emit([]);
    this._serviceReportes.EventDatosDescuentos$.emit([]);
    this._serviceReportes.FechaDesde$.emit( this.desde);
    this._serviceReportes.FechaHasta$.emit(this.hasta);

    switch (this.tiporpt_id) {
      case 1:
      if (this.form.controls.chkvehiculo.value === false) {
        this._serviceReportes
          .getventas("1", "10", this.desde, this.hasta, _condicion)
          .subscribe((resp: any) => {
            this._serviceReportes.EventDatosVentas$.emit(resp.data);
          });
      }
      else {
        if (this.form.controls.vehiculo_numero.value === '') {
          Swal.fire('Precaución', 'Número de Vehículo no válido...', 'warning');
          return false;
        }
        else
        {
          this._serviceReportes
            .getventasByBus("1", "10", this.desde, this.hasta, this.form.controls.vehiculo_numero.value)
            .subscribe((resp: any) => {
              this._serviceReportes.EventDatosVentas$.emit(resp.data);
            });
        }
      }
        
        break;

      case 2:

      if (this.form.controls.chkvehiculo.value === false) {
        this._serviceReportes
          .getdescuentos("1", "10", this.desde, this.hasta, _condicion)
          .subscribe((resp: any) => {
            this._serviceReportes.EventDatosDescuentos$.emit(resp.data);
          });
      }
      else {
        if (this.form.controls.vehiculo_numero.value === '') {
          Swal.fire('Precaución', 'Número de Vehículo no válido...', 'warning');
          return false;
        }
        else
        {
          console.log("ttttttttttttttttttttttttt");
          this._serviceReportes
            .getdescuentosByBus("1", "10", this.desde, this.hasta, this.form.controls.vehiculo_numero.value)
            .subscribe((resp: any) => {
              this._serviceReportes.EventDatosDescuentos$.emit(resp.data);
            });
        }
      }
        
        break;

      default:
        break;
    }
  }

  change_bus(value: string)
  {
    const lastchar = value.substr(value.length - 1);
    if (!(new RegExp('[0-9]').test(lastchar)))
    {
      value = value.substr(0, value.length - 1);
      this.form.controls["vehiculo_numero"].setValue(value);

    }
  }

  change_chk() {
    console.log('chkvehiculo: ', this.form.controls.chkvehiculo.value);
  }

}
