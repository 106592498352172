const pageMenus = [{
    'icon': 'fa fa-th-large',
    'title': 'Dashboard',
    'url': '',
    'caret': 'true',
    'state': 'expand',
    'hide': true,
    'expand': 'true',
    'submenu': [{
      'url': 'dashboard/v1',
      'title': 'Dashboard v1'
    }, {
      'url': 'dashboard/v2',
      'title': 'Dashboard v2'
    }, {
      'url': 'dashboard/v3',
      'title': 'Dashboard v3'
    }]
  }, {
    'icon': 'fa fa-home',
    'title': 'Inicio',
    'url': '/home'
  }, {
    'icon': 'fa fa-cogs',
    'title': 'Configuración',
    'url': '',
    'caret': 'true',
    'submenu': [{
      'url': '/clientes',
      'title': 'Clientes',
    }, {
      'url': '/empresa',
      'title': 'Empresa'
    }, {
      'url': '/marcas',
      'title': 'Marcas'
    }, {
      'url': '/productos',
      'title': 'Productos',
    }, {
      'url': '/vehiculos',
      'title': 'Vehículos'
    }, {
      'url': '/tarifas',
      'title': 'Tarifas Descuentos'
    }, 
    // {
    //   'url': '/tipos-cliente',
    //   'title': 'Tipos de Cliente'
    // }, 
    {
      'url': '/usuarios',
      'title': 'Usuarios del Sistema'
    }
  ]
  },  {
    'icon': 'fas fa-shopping-cart',
    'title': 'Ventas',
    'url': '/ventas'
  },  {
    'icon': 'fas fa-calculator',
    'title': 'Retiro Descuentos',
    'url': '/retiro-descuentos'
  },  {
    'icon': 'fas fa-search',
    'title': 'Consultar Descuentos',
    'url': '/consulta-descuentos'
  }
  ,  {
    'icon': 'fas fa-chart-line',
    'title': 'Dashboard',
    'url': '/dashboard'
  },{
    'icon': 'fa fa-print',
    'title': 'Reportes',
    'url': '/reportes'
  }, 
  /* {
    'icon': 'fa fa-image',
    'title': 'Utiles',
    'url': '',
    'caret': 'true',
    'submenu': [{
      'url': 'gallery/v1',
      'title': 'Gallery v1'
    }, {
      'url': 'gallery/v2',
      'title': 'Gallery v2'
    }]
  },  */
  {
    'icon': 'fa fa-key',
    'title': 'Login & Registro',
    'url': '',
    'caret': 'true',
    'submenu': [{
      'url': 'login',
      'title': 'Login'
    }, {
      'url': '/register',
      'title': 'Registrar'
    }]
  }];
  
  export default pageMenus;
