import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UsersService } from '../services/users.service';

@Injectable()
export class AuthorizatedGuard implements CanActivate  {
    constructor(private _serviceUsers: UsersService, private route: Router){

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        if (this._serviceUsers.currentUserObject !== null) {
            const currentUser = this._serviceUsers.currentUserObject;
            if (currentUser.activo === 1) {
                this._serviceUsers.isLogueado$.emit(true);
                return true;
            }
        }
        this._serviceUsers.logout();
        this._serviceUsers.isLogueado$.emit(false);
        this.route.navigate(['/login'], {queryParams: { returnUrl: state.url }});
        return false;
    }
}
