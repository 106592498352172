import { Component, OnInit } from '@angular/core';
import pageSettings from '../../config/page-settings';
import { User } from 'src/app/models/auth/users.model';
import { EmpresaService } from 'src/app/services/empresa.service';
import { UsersService } from 'src/app/services/users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: []
})
export class HomeComponent implements OnInit {
  empresa: string = '';
  linea2: string = '';

  pageSettings;
  pageHasScroll;
  currentUser: User;
  constructor(
    private _serviceEmpresa: EmpresaService,
    private _serviceUsers: UsersService,
    private router: Router
  ) { 
    this._serviceUsers.currentUser.subscribe(x => {
      this.currentUser = x
    });
  }

  ngOnInit() {
    this.pageSettings = pageSettings;
    this._serviceEmpresa.get_listar('1', '10').subscribe((resp: any) => {
      this.empresa = resp.data[0].razon_social;
      this.linea2 = resp.data[0].razon_social2;
      this._serviceUsers.empresa$.emit(this.empresa);
      this._serviceUsers.empresa2$.emit(this.linea2);
     
    });
  }

  onToggleSidebarRight(val: boolean): void {

  }

  onToggleMobileSidebar(val: boolean): void {

  }

  onToggleMobileRightSidebar(val: boolean): void {

  }

  onToggleSidebarMinified(val: boolean): void {

  }

  onHideMobileSidebar(val: boolean): void {

  }

  onHideMobileRightSidebar(val: boolean): void {

  }

  logOut(msg: string) {
    this._serviceUsers.logout();
    this.router.navigate(["/login"]);
  }

}
