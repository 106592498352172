import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProductosService } from '../../services/productos.service';
import { PaginadorService } from '../../paginador/paginador.service';
import pageSettings from '../../config/page-settings';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Producto } from '../../models/productos.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductosNewComponent } from './productos-new.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
    .modal-xl {
      max-width: 460px;
    }
    `
  ]
})
export class ProductosComponent implements OnInit {

  pageSettings = pageSettings;
  data_list = [];
  items = [1 , 2, 3, 4];
  buscando: boolean = false;
  frmBuscar: FormGroup;

  constructor(
    private _serviceProductos: ProductosService,
    private _servicePaginador: PaginadorService,
    private fb: FormBuilder,
    private modalService: NgbModal
    ) { 
      this.pageSettings.pageWithoutSidebar = false;
      this.pageSettings.pageTopMenu = false;
    }

  ngOnInit() {
    this.buscando = false;
    this.frmBuscar = this.fb.group({
      _txtBuscar: ['']
    });
   this.getLista('1', '10');
  }

  getLista(pagina: string, limit: string) {
    this._serviceProductos.get_listar('1', '10').subscribe((resp: any) => {
      this.data_list = resp.data;
      this._servicePaginador.page$.emit(pagina);
      this._servicePaginador.registros$.emit(resp.registros);
    });
  }

  getBuscar(pagina: string, limit: string, buscar: string) {
    this.buscando = true;
    this._serviceProductos.get_buscar(pagina, limit, buscar).subscribe((resp: any) => {
      this.data_list = resp.data;
      this._servicePaginador.page$.emit(pagina);
      this._servicePaginador.registros$.emit(resp.registros);
    });
  }

  receiveMessage($event: any) {
    if (this.frmBuscar.controls._txtBuscar.value === '') {
      this.buscando = false;
    }

    if (!this.buscando)
    {
      this.getLista($event.pagina, $event.recxpag);
    } 

    if (this.buscando)
    {
      const value = this.frmBuscar.controls._txtBuscar.value;
      this.getBuscar($event.pagina, $event.recxpag, value);
    } 

  }

  new (model: Producto) {
    const ref = this.modalService.open(ProductosNewComponent, { centered: true, size: <any>'xl', backdrop: 'static'});
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then((data) => {
      console.log("new data => ", data);
      if (data.valido) {
        this._serviceProductos.create(data.frm).subscribe((resp: any) => {
          if (resp.data === 'ok') {
            this.getLista('1', '10');
          }
          else {
            Swal.fire("Atención", resp.data, "error");
          }

        });
      }
      else {
        Swal.fire("Atención", "Los datos no son válidos", "error");
      }
    }, (cancel) => {

      });

  }

  Buscar() {
    const value = this.frmBuscar.controls._txtBuscar.value;
    if (value.length > 0) {
      this.buscando = true;
      this.getBuscar('1', '10', value);
    }
    else {
      this.getLista('1', '10');
    }
  }

  edit (model: Producto) {
    const ref = this.modalService.open(ProductosNewComponent, { centered: true, size: <any>'xl', backdrop: 'static'});
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.result.then((data) => {
      if (data.valido) {
        data.frm.fecha_sistema = Date();
        this._serviceProductos.update(model.producto_id.toString(), data.frm).subscribe((resp: any) => {
          if (resp.data === 'ok') {
            this.getLista('1', '10');
          }
          else {
            Swal.fire("Atención", resp.msg, "error");
          }

        });
      }
      else {
        Swal.fire("Atención", "Los datos no son válidos", "error");
      }
    }, (cancel) => {

      });


  }

  delete(model: Producto) {
     Swal.fire({
      title: '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html: '<p><b>Producto:</b> ' + model.nombre + '</p>',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this._serviceProductos.getdelete(model.producto_id.toString()).subscribe((resp: any) => {
          if (resp.data === 'ok') {
            this.getLista('1', '10');
            Swal.fire('Información', 'El registro ha sido eliminado...', 'success');
          }
          else {
            Swal.fire('Atención', resp.msg, 'warning');
          }
        });
      } 
    });
  }

}
