import { Component, Inject, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductosService } from "../services/productos.service";
import { Producto } from "../models/productos.model";
import { PaginadorService } from '../paginador/paginador.service';

export interface DialogData {
  producto_id: number;
  nombre: string;
}

@Component({
  selector: 'app-dlg-productos',
  templateUrl: './dlg-productos.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
    mat-form-field {
      width: 100%;
      font-size: 10px;
    }
    .btn.btn-link {
      padding: 0px;
    }

    mat-dialog {
      width: 200%;
    }

    `
  ]
})
export class DlgProductosComponent implements OnInit {

  @Output() submitClicked = new EventEmitter<any>();
  data_model: Producto[] = [];
  Titulo: string = "Productos";

  constructor(
      public dialogRef: MatDialogRef<DlgProductosComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      private _serviceModel: ProductosService,
      private _servicePaginador: PaginadorService
    ) {}

    ngOnInit() {
      this.data_model = [];
      this.getLista(1, 10);
      console.log("isEdit => ", this.data);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  BuscarRegistro(buscar: string) {
    if (buscar.length > 0) {
      this._serviceModel.get_buscar('1', '10', buscar).subscribe((resp: any) => {
        this.data_model = resp.data;
      });
    }
    else {
      this.getLista(1, 10);
    }
  }

  onSelect(id: number) {
    this.submitClicked.emit(this.data_model.filter(x => x.producto_id === id).map(m => ({ producto_id: m.producto_id, producto_nombre: m.nombre})));
    this.dialogRef.close();
  }

  getLista(pagina: number, recxpag: number) {
    this._serviceModel.get_listar(pagina.toString(), recxpag.toString()).subscribe((resp: any) => {
        this.data_model = resp.data;
        this._servicePaginador.page$.emit(pagina);
        this._servicePaginador.registros$.emit(resp.registros);
    });
  } 

  receiveMessage($event: any) {
    this.getLista($event.pagina, $event.recxpag);
  }

}
