import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ClientesService } from '../../services/clientes.service';
import { PaginadorService } from '../../paginador/paginador.service';
import pageSettings from '../../config/page-settings';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Cliente } from '../../models/clientes.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesNewComponent } from './clientes-new.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {

  pageSettings = pageSettings;
  sortedData = [];
  data_list = [];
  items = [1 , 2, 3, 4];
  buscando: boolean = false;
  frmBuscar: FormGroup;
  tipos_documento = [];

  constructor(
    private _serviceClientes: ClientesService,
    private _servicePaginador: PaginadorService,
    private fb: FormBuilder,
    private modalService: NgbModal
    ) { 
      this.pageSettings.pageWithoutSidebar = false;
      this.pageSettings.pageTopMenu = false;
    }

  ngOnInit() {
    this.buscando = false;
    this.frmBuscar = this.fb.group({
      _txtBuscar: ['']
    });
   this.getLista('1', '10');
  }

  getLista(pagina: string, limit: string) {
    this._serviceClientes.get_listar(pagina, limit).subscribe((resp: any) => {
      this.data_list = resp.data;
      this._servicePaginador.page$.emit(pagina);
      this._servicePaginador.registros$.emit(resp.registros);
    });
  }

  getBuscar(pagina: string, limit: string, buscar: string) {
    this.buscando = true;
    this._serviceClientes.get_buscar(pagina, limit, buscar).subscribe((resp: any) => {
      this.data_list = resp.data;
      this._servicePaginador.page$.emit(pagina);
      this._servicePaginador.registros$.emit(resp.registros);
    });
  }

  receiveMessage($event: any) {
    if (this.frmBuscar.controls._txtBuscar.value === '') {
      this.buscando = false;
    }

    if (!this.buscando)
    {
      this.getLista($event.pagina, $event.recxpag);
    } 

    if (this.buscando)
    {
      const value = this.frmBuscar.controls._txtBuscar.value;
      this.getBuscar($event.pagina, $event.recxpag, value);
    } 

  }

  new (model: Cliente) {
    const ref = this.modalService.open(ClientesNewComponent, { centered: true, size: 'lg', backdrop: 'static'});
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then((data) => {
      console.log("new data => ", data);
      if (data.valido) {
        this._serviceClientes.create(data.frm).subscribe((resp: any) => {
          if (resp.data === 'ok') {
            this.getLista('1', '10');
          }
          else {
            Swal.fire("Atención", resp.data, "error");
          }

        });
      }
      else {
        Swal.fire("Atención", "Los datos no son válidos", "error");
      }
    }, (cancel) => {

      });

  }

  Buscar() {
    const value = this.frmBuscar.controls._txtBuscar.value;
    if (value.length > 0) {
      this.buscando = true;
      this.getBuscar('1', '10', value);
    }
    else {
      this.getLista('1', '10');
    }
  }

  edit (model: Cliente) {
    const ref = this.modalService.open(ClientesNewComponent, { centered: true, size: 'lg', backdrop: 'static'});
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.result.then((data) => {
      console.log("edit data => ", model.cliente_id.toString());
      if (data.valido) {
        data.frm.fecha_sistema = Date();
        this._serviceClientes.update(model.cliente_id.toString(), data.frm).subscribe((resp: any) => {
          if (resp.data === 'ok') {
            this.getLista('1', '10');
          }
          else {
            Swal.fire("Atención", resp.msg, "error");
          }

        });
      }
      else {
        Swal.fire("Atención", "Los datos no son válidos", "error");
      }
    }, (cancel) => {

      });


  }

  delete(model: Cliente) {
     Swal.fire({
      title: '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html: '<p><b>Empleado:</b> ' + model.papellido + ' ' + model.sapellido + ' ' + model.pnombre + ' ' + model.snombre + '</p>',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this._serviceClientes.getdelete(model.cliente_id.toString()).subscribe((resp: any) => {
          console.log("resp: ", resp);
          if (resp.data === 'ok') {
            this.getLista('1', '10');
            Swal.fire('Información', 'El registro ha sido eliminado...', 'success');
          }
          else {
            Swal.fire('Atención', resp.msg, 'warning');
          }
        });
      } 
    });
  }

}
