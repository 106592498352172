import { Component, OnInit } from '@angular/core';
import { ReportesService } from '../../services/reportes.service';
import { EmpresaService } from '../../services/empresa.service';

@Component({
  selector: 'app-rpt-header',
  templateUrl: './rpt-header.component.html',
  styles: []
})
export class RptHeaderComponent implements OnInit {

  data_empresa = [];
  empresa = '';

  constructor(
    private _serviceReportes: ReportesService,
    private _serviceEmpresa: EmpresaService
  ) { }

  ngOnInit() {
    this._serviceEmpresa.get_listar('1', '10').subscribe((resp: any) => {
      this.data_empresa = resp.data;
      // this.empresa = resp
      console.log("x.x.x.x.x.x.x.x.x.x.x. => ", resp.data[0].razon_social);
    });
   /*  this._serviceReportes.data_empresa$.subscribe((resp) => {
      this.data_empresa = resp;
    }); */
  }

}
