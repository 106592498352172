import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ReportesService } from '../../services/reportes.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rpt-ventas',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './rpt-ventas.component.html',
  styles: [
    `
    .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 0px 8px;
    }
    `
  ]
})
export class RptVentasComponent implements OnInit {

  data_rpt = [];
  tituloReporte = 'Reporte de Ventas';
  fileName = '';
  FechaDesde = "";
  FechaHasta = "";
  Empresa = '';

  constructor(
    private _serviceReportes: ReportesService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this._serviceReportes.EventDatosVentas$.subscribe((resp: any) => {
      this.data_rpt = resp;
    });

    this._serviceReportes.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportes.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });
    
  }

  exportAsXLSX(): void {
    let titulos =  { A: "Fecha", B: "Factura", C: "Bus", D: "Nombre Socio", E: "Valor Venta", F: "Galones", G: "Combustible", H: "Precio Venta", I: "Descuento" };
    if (this.data_rpt.length === 0) {
        Swal.fire('Advertencia', 'No hay datos para exportar...', 'warning');
    } else {
        let itemsDisplay: any[] = [];
      itemsDisplay.push(titulos);
        this.data_rpt.map(c => {
          itemsDisplay.push({A: this.datePipe.transform(c.fecha_venta, "dd/MM/yyyy"), B: c.numero_recibo, C: c.vehiculo_numero, D: c.cliente_nombre, E: parseFloat(c.total_venta), F: parseFloat(c.total_galones), G: c.producto_nombre, H: parseFloat(c.precio_por_galon), I: parseFloat(c.total_descuento)});
        });
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(itemsDisplay, {skipHeader: true});
        const workbook: XLSX.WorkBook = { Sheets: { 'Ventas': worksheet }, SheetNames: ['Ventas'] };
        this.fileName = `Ventas${this.datePipe.transform(new Date(), "yyyy-MM-dd")}.xlsx`;
        XLSX.writeFile(workbook, this.fileName);
    }
  }

  get_Total_Venta() {
    let total = 0;
    this.data_rpt.forEach(x => {
      total += parseFloat(x.total_venta);
    });
    return total;
  }

  get_Total_Galones() {
    let total = 0;
    this.data_rpt.forEach(x => {
      total += parseFloat(x.total_galones);
    });
    return total;
  }

  get_Total_Descuento() {
    let total = 0;
    this.data_rpt.forEach(x => {
      total += parseFloat(x.total_descuento);
    });
    return total;
  }
}
