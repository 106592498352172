import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Vehiculo } from '../../models/vehiculos.model';
import * as moment from 'moment';
import { MarcasService } from '../../services/marcas.service';
import { VehiculosService } from '../../services/vehiculos.service';
import { MatDialog } from '@angular/material';
import { DlgClientesComponent } from '../../busquedas/dlg-clientes.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-vehiculos-new',
  templateUrl: './vehiculos-new.component.html',
  styles: [
    `
    input.form_control{ margin-top: 4px;}
    `
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class VehiculosNewComponent implements OnInit {

  isLoading: boolean = false;
  frm: FormGroup;
  marcas = [];
  data_search_clientes = [];
  _model: Vehiculo;
  _cliente = "";
  isEdit: boolean = false;
  _cliid: number = 0;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceMarcas: MarcasService,
    // private _serviceClientes: ClientesService,
    private _serviceVehiculos: VehiculosService,
    private dialog: MatDialog
    ) { }

  ngOnInit() {
        this.frm = this.fb.group({
        vehiculo_id: [0],
        cliente_id: ['', Validators.required],
        marca_id: [3, Validators.required],
        placa: ['', Validators.required],
        numero: ['', Validators.required],
        modelo: ['', Validators.required],
        _cliente_nombre: ['']
      });

    this._serviceMarcas.get_listar().subscribe((resp: any) => {
      this.marcas = resp.data;
    });

    if (this.isEdit) {
      const id = this._model.vehiculo_id;
      this.setForm(id);
    }
    
  }
  get fdata() { return this.frm.controls; }

  private setForm (id: number) {
    this._serviceVehiculos.getbyid(id.toString()).subscribe((resp: any) => {
        this._model = resp.data[0];
        
        this.frm = this.fb.group({
          vehiculo_id: [this._model.vehiculo_id],
          cliente_id: [this._model.cliente_id, Validators.required],
          marca_id: [this._model.marca_id, Validators.required],
          placa: [this._model.placa, Validators.required],
          numero: [this._model.numero, Validators.required],
          modelo: [this._model.modelo, Validators.required],
          _cliente_nombre: [this._cliente]
        });
    });

  }

  openDialog_Clientes(): void {
    const dialogRef = this.dialog.open(DlgClientesComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_clientes = result;
        this.frm.controls.cliente_id.patchValue(result[0].cliente_id);
        this.frm.controls._cliente_nombre.patchValue(
          result[0].cliente_nombre
        );
        this._cliid = result[0].cliente_id;
        dialogSubmitSubscription.unsubscribe();
      });
  }

  change_numero(value: string)
  {
    const lastchar = value.substr(value.length - 1);
    if (!(new RegExp('[0-9]').test(lastchar)))
    {
      value = value.substr(0, value.length - 1);
      this.frm.controls["numero"].setValue(value);

    }
  }

  change_modelo(value: string)
  {
    const lastchar = value.substr(value.length - 1);
    if (!(new RegExp('[0-9]').test(lastchar)))
    {
      value = value.substr(0, value.length - 1);
      this.frm.controls["modelo"].setValue(value);

    }
  }

}
