import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { URL_API } from "../config/config";

@Injectable({
  providedIn: 'root'
})
export class GraphVentasService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

constructor(private http: HttpClient) { }

  get_galones_dia() {
    const url = URL_API + '/graph_ventas/get_galones_dia/';
    return this.http.get(url);
  }

  get_galonesbyproducto() {
    const url = URL_API + '/graph_ventas/get_galonesbyproducto/';
    return this.http.get(url);
  }


  get_venta_bydia() {
    const url = URL_API + '/graph_ventas/get_venta_bydia/';
    return this.http.get(url);
  }

  get_venta_bymes() {
    const url = URL_API + '/graph_ventas/get_venta_bymes/';
    return this.http.get(url);
  }

  get_ventabyproducto() {
    const url = URL_API + '/graph_ventas/get_graph_ventabyproducto/';
    return this.http.get(url);
  }

  get_ventabyproductoultimosdias() {
    const url = URL_API + '/graph_ventas/get_ventabyproductoultimosdias/';
    return this.http.get(url);
  }

  get_venta_mes_actual() {
    const url = URL_API + '/graph_ventas/get_graph_ventamesactual/';
    return this.http.get(url);
  }

  get_graph_saldo_descuento() {
    const url = URL_API + '/graph_ventas/get_graph_saldo_descuento/';
    return this.http.get(url);
  }
}
