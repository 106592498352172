import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ReportesService } from '../../services/reportes.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-rpt-descuentos',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './rpt-descuentos.component.html',
  styles: [
    `
    .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 0px 8px;
    }
    `
  ]
})
export class RptDescuentosComponent implements OnInit {

  data_rpt = [];
  tituloReporte = 'Reporte de Descuentos acumulados';
  fileName = '';
  FechaDesde = "";
  FechaHasta = "";
  Empresa = '';

  constructor(
    private _serviceReportes: ReportesService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this._serviceReportes.EventDatosDescuentos$.subscribe((resp: any) => {
      this.data_rpt = resp;
    });

    this._serviceReportes.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportes.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });
    
  }

  exportAsXLSX(): void {
    let titulos =  { A: "Nombre Socio", B: "No. Vehículo", C: "Total Descuento", D: "Total Retiro", E: "Saldo" };
    if (this.data_rpt.length === 0) {
        Swal.fire('Advertencia', 'No hay datos para exportar...', 'warning');
    } else {
        let itemsDisplay: any[] = [];
      itemsDisplay.push(titulos);
        this.data_rpt.map(c => {
          itemsDisplay.push({A: c.cliente_nombre, B: c.vehiculo_numero, C: parseFloat(c.total_descuento), D: parseFloat(c.total_retiro), E: parseFloat(c.saldo)});
        });
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(itemsDisplay, {skipHeader: true});
        const workbook: XLSX.WorkBook = { Sheets: { 'Descuentos': worksheet }, SheetNames: ['Descuentos'] };
        this.fileName = `Descuentos${this.datePipe.transform(new Date(), "yyyy-MM-dd")}.xlsx`;
        XLSX.writeFile(workbook, this.fileName);
    }
  }

  get_Total_Descuento() {
    let total = 0;
    this.data_rpt.forEach(x => {
      total += parseFloat(x.total_descuento);
    });
    return total;
  }
  
  get_Total_Retiro() {
    let total = 0;
    this.data_rpt.forEach(x => {
      total += parseFloat(x.total_retiro);
    });
    return total;
  }

  get_Total_Saldo() {
    let total = 0;
    this.data_rpt.forEach(x => {
      total += parseFloat(x.saldo);
    });
    return total;
  }

  

}
