import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';
import { VentasService } from "../../services/ventas.service";

function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value === null || value.length === 0;
}

@Component({
  selector: 'app-ventas-validator',
  templateUrl: './ventas-validator.component.html',
  styles: []
})
export class VentasValidatorComponent {

  constructor(
    public _serviceModel: VentasService
  ) { }

  // static existingReciboValidator(_serviceModel: VentasService) {
  //   return(c: AbstractControl): Promise<null> | null => {
  //     console.log("abstract control: ", c);
  //     if(!c.valueChanges || c.pristine){
  //       return Promise.resolve(null);
  //       /* return {

  //         'existingReciboValidator':true
  //       } */
  //     }
  //     console.log("x.x.x.x..x");
  //     return Promise.resolve(null);
  //   }
  // }

  /* static existingReciboValidator(  _serviceModel: VentasService, control?: AbstractControl) {
    if (!control.valueChanges || control.pristine) {
      return (control: AbstractControl): Promise<null> => Promise.resolve(null);
    }
    else {
      return (control: AbstractControl) => _serviceModel.reciboExiste(control.value)
      .pipe(
          map((data: any) => {
              return (data.count > 0) ? { existingReciboValidator: true } : null;
          })
      );
    }
  } */

  gte(val: number): ValidatorFn {
 
    return (control: AbstractControl): ValidationErrors | null => {
  
      let v: number = +control.value;
      if (!this._serviceModel.reciboExiste(v.toString())) {
        return { 'gte': true, 'requiredValue': val }
      }
  
      return null;
    }
  }
  

  static existingReciboValidator ( _serviceModel: VentasService, modo?: boolean ) {
    if (modo === false) {
        return (control: AbstractControl): Promise<null> => Promise.resolve(null);
    }
    else {
        return (control: AbstractControl) => _serviceModel.reciboExiste(control.value)
        .pipe(
            map((data: any) => {
                return (data.count > 0) ? { existingReciboValidator: true } : null;
            })
        );
    }
}


}


