const pageSettings = {
    pageSidebarMinified: false,
    pageContentFullHeight: false,
    pageContentFullWidth: false,
    pageContentInverseMode: false,
    pageWithFooter: false,
    pageWithoutSidebar: false,
    pageSidebarRight: false,
    pageSidebarRightCollapsed: false,
    pageSidebarTwo: false,
    pageSidebarWide: false,
    pageSidebarTransparent: false,
    pageSidebarLight: false,
    pageSidebarSearch: false,
    pageTopMenu: false,
    pageEmpty: false,
    pageBodyWhite: false,
    pageMegaMenu: false,
    pageLanguageBar: false,
    pageMobileSidebarToggled: false,
    pageMobileSidebarFirstClicked: false,
    pageMobileSidebarRightToggled: false,
    pageMobileSidebarRightFirstClicked: false,
    pageMobileTopMenuToggled: false,
    pageMobileMegaMenuToggled: false
  };

  export default pageSettings;
