import { BrowserModule, Title } from '@angular/platform-browser';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AppRoutingModule } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarRightComponent } from './components/sidebar-right/sidebar-right.component';
import { HeaderComponent } from './components/header/header.component';
import { ClientesComponent } from './pages/clientes/clientes.component';
import { EmpresaComponent } from './pages/empresa/empresa.component';
import { FloatSubMenuComponent } from './components/float-sub-menu/float-sub-menu.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PanelComponent } from './components/panel/panel.component';
import { PaginadorComponent } from './paginador/paginador.component';
import { MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatNativeDateModule, MatSelectModule, MatSliderModule, MatToolbarModule } from '@angular/material';
import { ClientesNewComponent } from './pages/clientes/clientes-new.component';
import { VehiculosComponent } from './pages/vehiculos/vehiculos.component';
import { VehiculosNewComponent } from './pages/vehiculos/vehiculos-new.component';
import { MarcasComponent } from './pages/marcas/marcas.component';
import { MarcasNewComponent } from './pages/marcas/marcas-new.component';
import { DlgClientesComponent } from './busquedas/dlg-clientes.component';
import { TarifasDescuentosComponent } from './pages/tarifas-descuentos/tarifas-descuentos.component';
import { TarifasDescuentosNewComponent } from './pages/tarifas-descuentos/tarifas-descuentos-new.component';
import { VentasComponent } from './pages/ventas/ventas.component';
import { VentasNewComponent } from './pages/ventas/ventas-new.component';
import { DlgVehiculosComponent } from './busquedas/dlg-vehiculos.component';
import { DlgProductosComponent } from './busquedas/dlg-productos.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { ProductosNewComponent } from './pages/productos/productos-new.component';
import { EmpresaNewComponent } from './pages/empresa/empresa-new.component';
import { RptVentasComponent } from './pages/reportes/rpt-ventas.component';
import { ReportesComponent } from './pages/reportes/reportes.component';
import { NgxPrintModule } from 'ngx-print';
import { RptHeaderComponent } from './components/rpt-header/rpt-header.component';
import { LoginComponent } from './auth/login.component';
import { UsersComponent } from './auth/users.component';
import { UsersNewComponent } from './auth/users-new.component';
import { RegisterComponent } from './auth/register.component';
import { AuthValidatorComponent } from './auth/auth-validator.component';
import { AuthorizatedGuard } from './auth/authorizated-guard';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TrendModule } from 'ngx-trend';
import { RetiroDescuentosComponent } from './pages/retiro-descuentos/retiro-descuentos.component';
import { RetiroDescuentosNewComponent } from './pages/retiro-descuentos/retiro-descuentos-new.component';
import { DlgDescuentosComponent } from './busquedas/dlg-descuentos.component';
import { RptDescuentosComponent } from './pages/reportes/rpt-descuentos.component';
import { VentasValidatorComponent } from './pages/ventas/ventas-validator.component';
import { ConsultaDescuentosComponent } from './pages/retiro-descuentos/consulta-descuentos.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    TopMenuComponent,
    SidebarComponent,
    SidebarRightComponent,
    HeaderComponent,
    ClientesComponent,
    EmpresaComponent,
    FloatSubMenuComponent,
    PanelComponent,
    PaginadorComponent,
    ClientesNewComponent,
    VehiculosComponent,
    VehiculosNewComponent,
    MarcasComponent,
    MarcasNewComponent,
    DlgClientesComponent,
    TarifasDescuentosComponent,
    TarifasDescuentosNewComponent,
    VentasComponent,
    VentasNewComponent,
    DlgVehiculosComponent,
    DlgProductosComponent,
    DlgDescuentosComponent,
    ProductosComponent,
    ProductosNewComponent,
    EmpresaNewComponent,
    RptVentasComponent,
    ReportesComponent,
    RptHeaderComponent,
    LoginComponent,
    UsersComponent,
    UsersNewComponent,
    RegisterComponent,
    AuthValidatorComponent,
    HomeComponent,
    PageNotFoundComponent,
    DashboardComponent,
    RetiroDescuentosComponent,
    RetiroDescuentosNewComponent,
    DlgDescuentosComponent,
    RptDescuentosComponent,
    VentasValidatorComponent,
    ConsultaDescuentosComponent,
  ],
  entryComponents: [
    ClientesNewComponent,
    MarcasNewComponent,
    VehiculosNewComponent,
    VentasNewComponent,
    ProductosNewComponent,
    EmpresaNewComponent,
    TarifasDescuentosNewComponent,
    DlgClientesComponent,
    DlgVehiculosComponent,
    DlgProductosComponent,
    DlgDescuentosComponent,
    UsersNewComponent,
    RetiroDescuentosNewComponent
  ],

  imports: [
    FormsModule,
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoadingBarRouterModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSliderModule,
    MatToolbarModule,
    MatIconModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxPrintModule,
    NgxChartsModule,
    TrendModule
    
  ],
  providers: [PaginadorComponent, DatePipe, Title, AuthorizatedGuard, {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private router: Router, private titleService: Title, private route: ActivatedRoute) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const title = 'Estación de Servicio '; // + this.route.snapshot.firstChild.data['title'];
        this.titleService.setTitle(title);
      }
    });
  }
}
