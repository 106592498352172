import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { LoginObject } from "../models/auth/login-object.model";
import { Session } from "../models/auth/session.model";
import { URL_API } from '../config/config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  EventLogueado$ = new EventEmitter<any>();
  constructor(
    private http: HttpClient
  ) { }

 
  login(loginObj: LoginObject): Observable<Session> {
    let url = URL_API + '/users/login';
    return this.http.post(url, loginObj)
    .pipe(
      (map(this.extractData)));
  }

  logout(): Observable<Boolean> {
    let url = URL_API + '/users/logout';
    return this.http.post(url, {})
    .pipe(
      map(this.extractData));
    }

  private extractData(res: any) {
    let body = res;
    return body;
  }
}
