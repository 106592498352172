import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_API } from "../config/config";

@Injectable({
  providedIn: 'root'
})
export class TiposDocumentoService {

  constructor(
    private http: HttpClient
  ) { }

  getLista() {
    const url = URL_API + '/tipos_documento/get_listar';
    return this.http.get(url);
  }
}
