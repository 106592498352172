import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { EmpresaService } from '../services/empresa.service';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  submitted: boolean = false;
  messageLogin: string = '';
  data: Date = new Date();
  isLoading: boolean = false;
  showError: boolean = false;
  empresa: any;
  linea2: any;
  _errorLoguin: boolean = false;
  

  

  constructor(
    private _serviceUsers: UsersService,
    private _serviceEmpresa: EmpresaService,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this._serviceEmpresa.get_listar('1', '10').subscribe((resp: any) => {
      this.empresa = resp.data[0].razon_social;
      this.linea2 = resp.data[0].razon_social2;
    });
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required]
    });
  }

  get frm () { return this.loginForm.controls; }

  login(): void {
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    this._serviceUsers.login(this.loginForm.value).subscribe(resp => {
      console.log('jjjjjjjjjjjjjjjjjjjjjjjj', resp)
      if (!resp.ok) {
        this._errorLoguin = true;
       this.messageLogin = resp.mensaje;
       return;
      }
      this._errorLoguin = false;
      this.isLoading = false;
      this._serviceUsers.isAuthenticated = true;
      this._serviceUsers.isLogueado$.emit(true);

      this.router.navigate(['/home']);
    },
      error => {
        console.log("error: ", error)
        this.isLoading = false;
        this.showError = true;
      });
    
  }

}
