import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { URL_API } from "../config/config";
import { Venta } from '../models/ventas.model';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VentasService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  };

  constructor(private http: HttpClient) { }

  get_listar(page: string, limit: string) {
    const url = URL_API + '/ventas/get_listar/' + page + '/' + limit;
    return this.http.get(url);
  }

  get_listar_clientes_ventas(page: string, limit: string) {
    const url = URL_API + '/ventas/get_listar_clientes_ventas/' + page + '/' + limit;
    return this.http.get(url);
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    const url = URL_API + '/ventas/get_buscar/' + pagina + '/' + limit + '/' + buscar;
    return this.http.get(url);
  }

  create(model: any) {
    const url = URL_API + '/ventas/nuevo';
    return this.http.post(url, model, this.httpOptions);
  }

  getbyid(id: string) {
    const url = URL_API + '/ventas/get_byid/' + id;
    return this.http.get(url);
  }

  reciboExiste(rc: string) {
    let url = URL_API + `/ventas/get_reciboexiste/${rc}`;
    return this.http.get(url)
      .pipe(
        map((resp: any) => resp),
        catchError(err => {
          Swal.fire('Error', err, 'error');
          return of(false);
        })
      );
  }

  update(id: string, model: Venta) {
    const url = URL_API + '/ventas/update/' + id;
    return this.http.put(url, model, this.httpOptions);
  }

  getdelete (id: string)  {
    const url = URL_API + '/ventas/delete/' + id;
    return this.http.delete(url, this.httpOptions);
  }
}
