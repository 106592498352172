import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { RetiroDescuento } from '../../models/retiro-descuentos.model';
import { VehiculosService } from '../../services/vehiculos.service';
import { MatDialog } from '@angular/material';
import { DlgDescuentosComponent } from '../../busquedas/dlg-descuentos.component';
import { RetiroDescuentosService } from '../../services/retiro-descuentos.service';
import Swal from 'sweetalert2';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-retiro-descuentos-new',
  templateUrl: './retiro-descuentos-new.component.html',
  styles: [
    `
    input.form_control{ margin-top: 4px;}
    `
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class RetiroDescuentosNewComponent implements OnInit {

  isLoading: boolean = false;
  frm: FormGroup;
  productos = [];
  vehiculos = [];
  data_search_descuentos = [];
  _model: RetiroDescuento;
  _cliente = "";
  isEdit: boolean = false;
  _vehiid: number = 0;
  _vehinum: string = '';
  _total_descuento: number = 0;
  _saldo_pasa: number = 0;
  _saldo_anterior: number = 0;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceRetiroDescuentos: RetiroDescuentosService,
    private _serviceVehiculos: VehiculosService,
    private dialog: MatDialog
    ) { }

  ngOnInit() {
        this.frm = this.fb.group({
          retirodescuento_id: [0],
          vehiculo_id: ['', Validators.required],
          fecha_retiro: ['', Validators.required],
          valor_retiro: [0, Validators.required],
          _cliente_nombre: [''],
          _vehiculo_numero: [''],
          _total_descuento: [0],
          _saldo_anterior: [0],
          _saldo_pasa: [0]
      });

    this._serviceVehiculos.get_listar('1', '10').subscribe((resp: any) => {
      this.vehiculos = resp.data;
    });

    if (this.isEdit) {
      const id = this._model.retirodescuento_id;
      this.setForm(id);
    }
    
  }
  get fdata() { return this.frm.controls; }

  private setForm (id: number) {
    this._serviceRetiroDescuentos.getbyretiroid(id.toString()).subscribe((resp: any) => {
        this._model = resp.data[0];
        this.frm = this.fb.group({
          retirodescuento_id: [this._model.retirodescuento_id],
          vehiculo_id: [this._model.vehiculo_id, Validators.required],
          fecha_retiro: [this._model.fecha_retiro, Validators.required],
          valor_retiro: [Number(this._model.valor_retiro), Validators.required],
          _cliente_nombre: [this._cliente],
          _vehiculo_numero: [this._model.vehiculo_numero],
          _total_descuento: [Number(this._model.total_descuento_vehiculo)],
          _saldo_pasa: [Number(this._model.total_descuento_vehiculo) - Number(this._model.valor_retiro)],
          _saldo_anterior: [Number(this._model.saldo) + Number(this._model.valor_retiro)]
        });
        this.calculos();
    });
  }

  openDialog_Descuentos(): void {
    const dialogRef = this.dialog.open(DlgDescuentosComponent, {
      width: "800px",
      data: {
        isEdit: this.isEdit
      },
    });

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe((result: any) => {
        this.data_search_descuentos = result;
        this.frm.controls.vehiculo_id.patchValue(result[0].vehiculo_id);
        this.frm.controls._total_descuento.patchValue(parseFloat(result[0].total_descuento).toFixed(1));
        this.frm.controls._saldo_anterior.patchValue(parseFloat(result[0].saldo).toFixed(1));
        this.frm.controls._vehiculo_numero.patchValue(result[0].vehiculo_numero);
        this.frm.controls.valor_retiro.patchValue(parseFloat(result[0].saldo).toFixed(1));
        this.frm.controls._cliente_nombre.patchValue(result[0].cliente_nombre);
        this._vehinum = result[0].vehiculo_numero;
        dialogSubmitSubscription.unsubscribe();
        this.calculos();
      });
  }

  calculos(){

    
    if (parseFloat(this.frm.controls.valor_retiro.value) > 0) {
        if (parseFloat(this.frm.controls.valor_retiro.value) > parseFloat(this.frm.controls._saldo_anterior.value)) {
            Swal.fire("Atención", "El valor del retiro es mayor que el saldo..", "error");
            return;
        }
        let saldo_pasa = parseFloat(this.frm.controls._saldo_anterior.value) - parseFloat(this.frm.controls.valor_retiro.value);
        this.frm.controls._saldo_pasa.patchValue(saldo_pasa.toFixed(0));
    }
  }

  change(value: string)
  {
    const lastchar = value.substr(value.length - 1);
    if (!(new RegExp('[0-9]').test(lastchar)))
    {
      value = value.substr(0, value.length - 1);
      this.frm.controls["valor_retiro"].setValue(value);

    }
  }

}
