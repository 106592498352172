import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmpresaService } from '../../services/empresa.service';
import { Empresa } from '../../models/empresa.model';


@Component({
  selector: 'app-empresa-new',
  templateUrl: './empresa-new.component.html',
  styles: [
    `
    input.form_control{ margin-top: 4px;}
    `
  ],
  providers: []
})
export class EmpresaNewComponent implements OnInit {

  isLoading: boolean = false;
  frm: FormGroup;
  _model: Empresa;

  isEdit: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder,
    private _serviceEmpresa: EmpresaService,
    ) { }

  ngOnInit() {
        this.frm = this.fb.group({
        empresa_id: [0],
        razon_social: ['', Validators.required],
        nit: ['', Validators.required],
        dv: ['', Validators.compose([Validators.required, Validators.maxLength(1) ])],
        direccion: [''],
        telefono: [''],
        email: ['', Validators.email],
      });

    if (this.isEdit) {
      const id = this._model.empresa_id;
      this.setForm(id);
    }
    
  }
  get fdata() { return this.frm.controls; }

  private setForm (id: number) {
    this._serviceEmpresa.getbyid(id.toString()).subscribe((resp: any) => {
        this._model = resp.data[0];
        this.frm = this.fb.group({
          empresa_id: [this._model.empresa_id],
          razon_social: [this._model.razon_social, Validators.required],
          nit: [this._model.nit, Validators.required],
          dv: [this._model.dv, Validators.compose([Validators.required, Validators.maxLength(1) ])],
          direccion: [this._model.direccion],
          telefono: [this._model.telefono],
          email: [this._model.email, Validators.email]
        });
    });

  }

  change_nit(value: string)
  {
    const lastchar = value.substr(value.length - 1);
    if (!(new RegExp('[0-9]').test(lastchar)))
    {
      value = value.substr(0, value.length - 1);
      this.frm.controls["nit"].setValue(value);

    }
  }

  change_dv(value: string)
  {
    const lastchar = value.substr(value.length - 1);
    if (!(new RegExp('[0-9]').test(lastchar)))
    {
      value = value.substr(0, value.length - 1);
      this.frm.controls["dv"].setValue(value);

    }
  }

}
