import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { URL_API } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {
  EventDatosVentas$ = new EventEmitter<any>();
  EventDatosDescuentos$ = new EventEmitter<any>();
  FechaDesde$ = new EventEmitter<any>();
  FechaHasta$ = new EventEmitter<any>();

  constructor(
    private http: HttpClient
  ) { }

  getventas(page: string, limit: string, desde: string, hasta: string, condicion: string) {
    if (condicion.length === 0) {
      condicion = '-1';
    }
    let url = URL_API + '/reportes/get_v_ventas/' + page + '/' + limit + '/' + desde + '/' + hasta + '/' + condicion;
    return this.http.get(url);
  }

  getventasByBus(page: string, limit: string, desde: string, hasta: string, bus: string) {
    let url = URL_API + '/reportes/get_v_ventasByBus/' + page + '/' + limit + '/' + desde + '/' + hasta + '/' + bus;
    return this.http.get(url);
  }

  getdescuentos(page: string, limit: string, desde: string, hasta: string, condicion: string) {
    if (condicion.length === 0) {
      condicion = '-1';
    }
    let url = URL_API + '/reportes/get_descuentos/' + page + '/' + limit + '/' + desde + '/' + hasta + '/' + condicion;
    return this.http.get(url);
  }

  getdescuentosByBus(page: string, limit: string, desde: string, hasta: string, bus: string) {
    let url = URL_API + '/reportes/get_descuentosByBus/' + page + '/' + limit + '/' + desde + '/' + hasta + '/' + bus;
    console.log("url => ", url);
    return this.http.get(url);
  }
}
