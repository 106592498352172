import { Component, Inject, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientesService } from "../services/clientes.service";
import { VCliente } from "../models/v-clientes.model";
import { PaginadorService } from '../paginador/paginador.service';

export interface DialogData {
  cliente_id: number;
  cliente_nombre: string;
}

@Component({
  selector: 'app-dlg-clientes',
  templateUrl: './dlg-clientes.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
    mat-form-field {
      width: 100%;
      font-size: 10px;
    }
    .btn.btn-link {
      padding: 0px;
    }

    mat-dialog {
      width: 200%;
    }

    `
  ]
})
export class DlgClientesComponent implements OnInit {

  @Output() submitClicked = new EventEmitter<any>();
  data_model: VCliente[] = [];
  Titulo: string = "Clientes";

  constructor(
      public dialogRef: MatDialogRef<DlgClientesComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      private _serviceModel: ClientesService,
      private _servicePaginador: PaginadorService
    ) {}

    ngOnInit() {
      this.data_model = [];
      this.getLista(1, 10);
      console.log("isEdit => ", this.data);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  BuscarRegistro(buscar: string) {
    if (buscar.length > 0) {
      this._serviceModel.get_buscar('1', '10', buscar).subscribe((resp: any) => {
        this.data_model = resp.data;
      });
    }
    else {
      this.getLista(1, 10);
    }
  }

  onSelect(id: number) {
    this.submitClicked.emit(this.data_model.filter(x => x.cliente_id === id).map(m => ({ cliente_id: m.cliente_id, cliente_nombre: m.cliente_nombre})));
    this.dialogRef.close();
  }

  getLista(pagina: number, recxpag: number) {
    this._serviceModel.get_listar_vclientes(pagina.toString(), recxpag.toString()).subscribe((resp: any) => {
        this.data_model = resp.data;
        this._servicePaginador.page$.emit(pagina);
        this._servicePaginador.registros$.emit(resp.registros);
    });
  } 

  receiveMessage($event: any) {
    this.getLista($event.pagina, $event.recxpag);
  }

}
